import type { OverlappingSpinnerProps } from './Types'
import { memo } from 'react'
import Spinner from './Spinner'
import cn from 'lib/utils/cn'

function OverlappingSpinner({
  visible = true,
  containerClassName,
  backdropClassName,
  ...restProps
}: Readonly<OverlappingSpinnerProps>) {
  return (
    <div className={cn(
      'absolute flex flex-col items-center justify-center inset-0 z-50 bg-transparent transition-opacity',
      visible ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
      containerClassName
    )}>
      <div className={cn('absolute inset-0 bg-white/60 z-10', backdropClassName)} />
      <Spinner {...restProps} />
    </div>
  )
}

export default memo(OverlappingSpinner)
