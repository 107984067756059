'use client'

import { useTranslation } from 'lib/i18n/useClientTranslation'
import Spinner from 'atoms/Spinner'
import { Button } from 'atoms/Button'
import useData from './useData'

function ViewHandler() {
  const { t } = useTranslation('screens')
  const { loading, authId, apps } = useData()

  return loading ? (
    <div className="flex flex-col items-center justify-center grow">
      <Spinner />
    </div>
  ) : (
    <div>
      <h1 className='text-center'>{t('home.title','Open VayaPin Application')}</h1>
      <p className="text-sm my-5 text-center">{authId}</p>
      {apps.map((app) => (
        <Button
          key={app.key}
          href={app.href}
          type='link'
          className="my-8"
        >
          {app.title}
        </Button>
      ))}
    </div>
  )
}

export default ViewHandler
